import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
    user: {},
  },
  getters: {
    getDrawer(state) {
      return state.drawer;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    setDrawer(state, payload) {
      state.commit("SET_DRAWER", payload);
    },
    setUser(context, payload) {
      context.commit("SET_USER", payload);
    },
  },
  modules: {},
});
