import Vue from "vue";
import VueRouter from "vue-router";
const MainLanding = () => import("@/pages/MainLanding");
const Features = () => import("@/pages/MainLandingPages/Features");
const Home = () => import("@/pages/MainLandingPages/Home");
const Pricing = () => import("@/pages/MainLandingPages/Pricing");
const MainAccount = () => import("@/pages/MainAccount");
const Welcome = () => import("@/pages/AccountPages/Welcome");
const CreateAccount = () => import("@/pages/AccountPages/CreateAccount");
const AddAdvertising = () => import("@/pages/AccountPages/AddAdvertising");
const MyCampaigns = () => import("../pages/AccountPages/MyCampaigns");
const MyCampaignDetails = () =>
  import("@/pages/AccountPages/MyCampaignDetails");
const Blog = () => import("@/pages/MainLandingPages/Blog");
const Company = () => import("@/pages/MainLandingPages/Company");
const ContactUs = () => import("@/pages/MainLandingPages/ContactUs");
const BlogItem = () => import("@/pages/MainLandingPages/BlogDetails");
const GoogleAddsAccounts = () =>
  import("../pages/AccountPages/GoogleAddsAccounts");
const ManageAccounts = () => import("../pages/AccountPages/ManageAccounts");
const Services = () => import("../pages/MainLandingPages/Services");
const ProfileSettings = () => import("../pages/AccountPages/ProfileSettings");
const Report = () => import("../pages/AccountPages/Report");
const Help = () => import("../pages/AccountPages/Help");
const MainAdverisingGoals = () =>
  import("@/components/Account/MainAdverisingGoals");
const BasicInfo = () => import("@/components/Account/BasicInfo");
const SuggestKeyword = () => import("@/components/Account/SuggestKeyword");
const ApproveOrEdit = () => import("@/components/Account/ApproveOrEdit");
const SetBudget = () => import("@/components/Account/SetBudget");
const ReviewCompany = () => import("@/components/Account/ReviewCompany");
const Payment = () => import("@/components/Account/Payment");
const ShowYourAddress = () => import("@/components/Account/ShowYourAddress");
const AllDone = () => import("@/components/Account/AllDone");
const ChoosePlan = () =>
  import("@/pages/AccountPages/add-advertising/ChoosePlan");
const SetBudgetAndPayment = () =>
  import("@/pages/AccountPages/add-advertising/SetBudgetAndPayment");
const Billing = () => import("@/pages/Billing");
const Privacy = () => import("@/pages/Privacy");
const TermsConditions = () => import("@/pages/TermsConditions");
const LadingPage = () => import("@/pages/LandingPage/LandingV1.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: Privacy,
  },
  {
    path: "/terms-of-use",
    name: "TermsConditions",
    component: TermsConditions,
  },
  {
    path: "/landing-page",
    name: "LandingPageV1",
    component: LadingPage,
  },
  {
    path: "/",
    name: "MainLanding",
    component: MainLanding,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/features",
        name: "Features",
        component: Features,
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
      },
      {
        path: "/services",
        name: "Services",
        component: Services,
      },
      {
        path: "/blog",
        name: "Blog",
        component: Blog,
      },
      {
        path: "/blog/:slug",
        name: "BlogDetail",
        component: BlogItem,
      },
      {
        path: "/company",
        name: "Company",
        component: Company,
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
      },
    ],
  },
  {
    path: "/account",
    name: "MainAccount",
    component: MainAccount,
    children: [
      {
        path: "/welcome",
        name: "Welcome",
        component: Welcome,
      },
      {
        path: "/create-account",
        name: "CreateAccount",
        component: CreateAccount,
      },
      {
        path: "/add-advertising",
        name: "AddAdvertising",
        component: AddAdvertising,
        children: [
          {
            path: "advertising-goals",
            name: "AdvertisingGoals",
            component: MainAdverisingGoals,
          },
          {
            path: "basic-info",
            name: "BasicInfo",
            component: BasicInfo,
          },
          {
            path: "suggest-keyword",
            name: "SuggestKeywords",
            component: SuggestKeyword,
          },
          {
            path: "show-address",
            name: "ShowYourAddress",
            component: ShowYourAddress,
          },
          {
            path: "approve-edit",
            name: "ApproveOrEdit",
            component: ApproveOrEdit,
          },
          {
            path: "set-budget",
            name: "SetBudget",
            component: SetBudget,
          },
          {
            path: "review-company",
            name: "ReviewCompany",
            component: ReviewCompany,
          },
          {
            path: "payment",
            name: "Payment",
            component: Payment,
          },
          {
            path: "choose-plan",
            name: "ChoosePlan",
            component: ChoosePlan,
          },
          {
            path: "set-budget-payment",
            name: "SetBudgetAndPayment",
            component: SetBudgetAndPayment,
          },
          {
            path: "all-done",
            name: "AllDone",
            component: AllDone,
          },
        ],
      },
      {
        path: "/my-campaigns",
        name: "MyCampaigns",
        component: MyCampaigns,
      },
      {
        path: "/my-campaigns-detail/:id",
        name: "MyCampaignsDetails",
        component: MyCampaignDetails,
      },
      {
        path: "/google-ads-account",
        name: "GoogleAdsAccount",
        component: GoogleAddsAccounts,
      },
      {
        path: "/manage-accounts",
        name: "ManageAccounts",
        component: ManageAccounts,
      },
      {
        path: "/profile-settings",
        name: "ProfileSettings",
        component: ProfileSettings,
      },
      {
        path: "/report",
        name: "Report",
        component: Report,
      },
      {
        path: "/billing",
        name: "Billing",
        component: Billing,
      },
      {
        path: "/help",
        name: "Help",
        component: Help,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
