export const PERIODS = {
  0: "daily_text",
  1: "weekly_text",
  2: "monthly",
  3: "total",
};

export const CURRENCIES = {
  0: "$",
  1: "EUR",
};

export const numberWithSpaces = (val) => {
  let phone = val.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
  return phone.replaceAll(" ", "-");
};

export const CAMPAIGN_STATUSES = {
  0: "Created",
  1: "active_chip",
  2: "paused_chip",
  3: "Incomplete",
  4: "Archived",
  5: "Deleted",
};

export const currentDate = new Date().getFullYear();
export const notify = (title, type) => {
  this.$notify({
    group: "foo",
    type,
    title,
  });
};


export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

