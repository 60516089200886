import axios from "axios";

export const coreApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// coreApi.interceptors.response.use(
//   function (response) {
//     // Doing something with the response data
//     console.log("working its");
//     return response;
//   },
//   function (error) {
//     // Handling response error
//     if (error.response.status === 401) {
//       // maybe refresh token, logout user, etc., based on your application design
//       console.log(401);
//     }
//     return Promise.reject(error);
//   }
// );
