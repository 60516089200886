import { coreApi } from "../index";
import axios from "axios";
import { baseUrl } from "@/main";

export const authService = {
  login: (user) => coreApi.post("/user", user),
  getUserInfo: () => axios.get(baseUrl + "" + "/users/info"),
  updateUserInfos: (data) =>
    axios.post(baseUrl + "" + "/users/updateUser", data),
  updateUserPassword: (data) =>
    axios.put(baseUrl + "/users/updateUserPassword", data),
  setDefaultCampaignPaymentMethod: (campaignId, paymentMethodId) =>
    axios.post(
      baseUrl +
        `/users/setDefaultCampaignPaymentMethod?campaignId=${campaignId}&paymentMethodId=${paymentMethodId}`
    ),
  createSubscriptionWithCoupon: ({
    paymentMethodId,
    productId,
    annualStatus,
    couponId,
  }) =>
    axios.post(
      baseUrl + `/users/createSubscription?paymentMethodId=${paymentMethodId}&productId=${productId}&annual=${annualStatus}&couponId=${couponId}`
    ),
  createSubscription: ({
    paymentMethodId,
    productId,
    annualStatus,
  }) =>
    axios.post(
      baseUrl + `/users/createSubscription?paymentMethodId=${paymentMethodId}&productId=${productId}&annual=${annualStatus}`
    ),
  checkCoupon: (val) =>
    axios.get(baseUrl + `/users/checkPromoCode?promoCode=${val}`),
  changeSubscription: (planId, annualStatus) =>
    axios.post(
      baseUrl +
        `/users/changeSubscription?newProductId=${planId}&annual=${annualStatus}`
    ),
  attachMethods: (paymentId) =>
    axios.post(
      baseUrl + `/users/attachPaymentMethod?paymentMethodId=${paymentId}`
    ),
  setDefaultMethod: (paymentId) =>
    axios.post(
      baseUrl + `/users/setDefaultPaymentMethod?paymentMethodId=${paymentId}`
    ),
  sendMessage: (data) => coreApi.post(`users/request`, data),
  getUpComingInvoice: () => axios.get(baseUrl + `/users/getUpcomingInvoice`),
  getInvoices: (limit) => axios.get(baseUrl + `/users/getInvoices/${limit}`),
  getBillingBreakdown: (month, year) =>
    axios.get(baseUrl + `/users/getBillingBreakdown/${month}/${year}`),
};

export const getToken = () => {
  const tokenU = localStorage.getItem("token");
  return tokenU;
};
