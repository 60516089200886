<template>
  <v-app className="app">
    <v-main>
      <router-view />
    </v-main>
    <notifications group="foo" />
    <NavDrawerHeader />
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
import { authService } from "@/services/auth";
import { currentDate } from "@/utils";
import axios from "axios";

export default {
  name: "App",
  components: {
    NavDrawerHeader: () => import("@/components/MainLanding/NavDrawerHeader"),
  },
  async created() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      try {
        const { data } = await authService.getUserInfo();
        this.setUser(data);
      } catch (err) {
        await this.$router.push("/");
        localStorage.clear();
      }
    }
  },
  methods: mapActions(["setUser"]),
  computed: {
    getRouteName() {
      return this.$route.name;
    },
    getCurrentYear() {
      return currentDate;
    },
  },
};
</script>
<style>
@import "assets/style/style.scss";
</style>
