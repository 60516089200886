import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Notifications from "vue-notification";
import "leaflet/dist/leaflet.css";
import VueApexCharts from "vue-apexcharts";
import vueDebounce from "vue-debounce";
import VueMask from "v-mask";
import i18n from "./i18n";
import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    if (error.response.status === 401) {
      //       // maybe refresh token, logout user, etc., based on your application design
      //       console.log(401);
      //     }
      this.$router.push("/");
      localStorage.clear();
      return Promise.reject(error);
    }
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

Vue.use(VueMask);
Vue.use(VueApexCharts);
Vue.use(vueDebounce, {
  defaultTime: "1s",
});
Vue.component("apexcharts", VueApexCharts);
export const baseUrl = process.env.VUE_APP_BASE_URL;
Vue.config.productionTip = false;

Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
